import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../../utils/formatDate";
import "./ArtworkMaterial.css";
import specsFile from "../../../../assets/Castle Elite & Targeted Advertising Submission Descriptions and Requirements.pdf";
import DeleteUploadModal from "../../../../components/Modals/DeleteUploadModal";
import { setMessageError } from "../../../../redux/actions";

export default function ArtworkMaterial() {
  const user = useSelector((state) => state.user);

  const [showData, setShowData] = useState([]);
  const [content, setContent] = useState([]);
  const [deleteUpload, setDeleteUpload] = useState(null);
  const [isLoading, setIsLoading] = useState({ status: false, uuid: null });

  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const dispatch = useDispatch();

  const tableHeader = [
    { title: "Ad Type", key: "title" },
    { title: "Option", key: "option" },
    { title: "Due Date", key: "dueDate" },
    { title: "Status", key: "status" },
    { title: "File Name", key: "fileName" },
    { title: "URL / Product SKU (if applicable)" },
    { title: "File Actions" },
  ];

  useEffect(() => {
    if (user.role === "Vendor") {
      loadContent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const loadContent = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/vendor/ads",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setContent(response.data);
      setIsLoading({ status: false, uuid: null });
    } catch (err) {
      setIsLoading({ status: false, uuid: null });
      if (err.response.data.message) {
        dispatch(setMessageError(err.response.data.message));
      } else {
        dispatch(
          setMessageError(err.response.status + " - " + err.response.statusText)
        );
      }
    }
  };

  const updateUrlInput = (e, uuid) => {
    const updatedContent = showData.map((item) => {
      if (item.uuid === uuid) {
        return {
          ...item,
          url: e.target.value,
        };
      } else {
        return item;
      }
    });
    setShowData(updatedContent);
  };

  const toggleUrlDisable = (uuid) => {
    const updatedContent = showData.map((item) => {
      if (item.uuid === uuid) {
        return {
          ...item,
          editDisabled: !item.editDisabled,
          url: item.originalURL,
        };
      } else {
        return item;
      }
    });
    setShowData(updatedContent);
  };

  const saveURL = async (material) => {
    try {
      await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/vendor/ads/url/" + material.uuid,
        { url: material.url },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      const updatedContent = showData.map((item) => {
        if (item.title === material.title && item.option === material.option) {
          return {
            ...item,
            editDisabled: true,
            originalURL: item.url,
          };
        } else {
          return item;
        }
      });
      setShowData(updatedContent);
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessageError(err.response.data.message));
      } else {
        dispatch(
          setMessageError(err.response.status + " - " + err.response.statusText)
        );
      }
    }
  };

  const uploadFile = async (e, material) => {
    try {
      // max 100MB
      if (!e.target.files[0] || e.target.files[0].size > 104857600) {
        return;
      }

      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);

      setIsLoading({ status: false, uuid: material.uuid });

      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/vendor/ads/" + material.uuid,
        formData,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      loadContent();
    } catch (err) {
      if (err.response.data.message) {
        dispatch(setMessageError(err.response.data.message));
      } else {
        dispatch(
          setMessageError(err.response.status + " - " + err.response.statusText)
        );
      }
    }
  };

  useEffect(() => {
    let selectedData = [...showData];

    if (sortBy) {
      if (sortDirection === "asc") {
        selectedData.sort((a, b) => (a[sortBy] > b[sortBy] ? 1 : -1));
      } else {
        selectedData.sort((a, b) => (a[sortBy] > b[sortBy] ? -1 : 1));
      }
    }
    setShowData(selectedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (content.length > 0) {
      let newContent = content.map((item) => ({
        ...item,
        status: item.addedAt ? "Uploaded" : "Not Uploaded",
        editDisabled: true,
        originalURL: item.url,
      }));

      setShowData(newContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  return (
    <div className="contentbar">
      <div className="col-lg-12  dashboard-bg">
        <div className="table-responsive">
          <div
            id="default-datatable_wrapper"
            className="dataTables_wrapper container-fluid dt-bootstrap4"
          >
            <div className="row">
              <div className="col-sm-12 table-scroll">
                <h5>Notice:</h5>
                <p className="mb-4">
                  Maximum file size is 100MB. The following file types are
                  accepted: .pdf, .jpeg, .png, .jpg, .doc, .docx, .rtf, .odt,
                  .csv, .ppt, .xml, .txt, .zip, .psd, .ai, .eps
                </p>
                <div className="mb-4 download-btn">
                  <a
                    href={specsFile}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-outline-primary"
                  >
                    <i className="feather icon-download mr-2"></i> Download Our
                    Specs Guide
                  </a>
                </div>
                <table
                  id="default-datatable"
                  className="display table table-striped table-bordered dataTable dtr-inline"
                  role="grid"
                  style={{ minWidth: "700px" }}
                >
                  <thead>
                    <tr>
                      {tableHeader.map((header) => (
                        <th
                          className={
                            !header.key
                              ? null
                              : sortBy !== header.key
                              ? "sorting"
                              : sortDirection === "asc"
                              ? "sorting_asc"
                              : "sorting_desc"
                          }
                          key={header.title}
                          onClick={() => {
                            if (header.key) {
                              setSortBy(header.key);
                              setSortDirection(
                                sortDirection === "asc" ? "desc" : "asc"
                              );
                            }
                          }}
                        >
                          {header.title}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {showData.length > 0 &&
                      showData.map((info, i) => (
                        <tr key={info.uuid}>
                          <td>{info.title}</td>
                          <td>{info.option}</td>
                          <td
                            className={
                              info.addedAt
                                ? null
                                : new Date(info.dueDate) > new Date()
                                ? null
                                : "text-danger"
                            }
                          >
                            {formatDate(info.dueDate)}
                          </td>
                          <td
                            className={
                              info.status === "Uploaded"
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            {info.status}
                          </td>
                          <td>{info.fileName}</td>
                          <td>
                            <input
                              type="text"
                              name="url"
                              id="url"
                              value={info.url}
                              disabled={info.editDisabled}
                              onChange={(e) => updateUrlInput(e, info.uuid)}
                              className="url-input"
                            />
                            <div className="icons-wrapper mt-2">
                              {info.editDisabled ? (
                                <div
                                  className="text-primary cursor-pointer"
                                  onClick={() => toggleUrlDisable(info.uuid)}
                                >
                                  <i className="feather icon-edit mr-2"></i>edit
                                </div>
                              ) : (
                                <>
                                  <div
                                    className="text-danger cursor-pointer"
                                    onClick={() => toggleUrlDisable(info.uuid)}
                                  >
                                    Cancel
                                  </div>
                                  <div
                                    className="text-success cursor-pointer"
                                    onClick={() => saveURL(info)}
                                  >
                                    <i className="feather icon-save mr-2"></i>
                                    Save
                                  </div>
                                </>
                              )}
                            </div>
                          </td>
                          <td>
                            <div className="icons-wrapper icons-wrapper-upload">
                              {isLoading.uuid === info.uuid ? (
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  disabled
                                >
                                  <span
                                    className="spinner-border spinner-border-sm mr-2"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  Loading...
                                </button>
                              ) : (
                                <>
                                  <label
                                    htmlFor={"upload" + info.uuid}
                                    className="btn btn-primary mb-0"
                                  >
                                    <i className="feather icon-upload mr-2"></i>
                                    Upload
                                  </label>
                                  <input
                                    type="file"
                                    name={"upload" + info.uuid}
                                    id={"upload" + info.uuid}
                                    accept=".pdf, .jpeg, .png, .jpg, .doc, .docx, .rtf, .odt,
                                .csv, .ppt, .xml, .txt, .zip, .psd, .ai, .eps"
                                    style={{ display: "none" }}
                                    onChange={(e) => {
                                      uploadFile(e, info);
                                    }}
                                  />
                                </>
                              )}

                              {info.addedAt && (
                                <>
                                  <a
                                    href={`${process.env.REACT_APP_AWS}/${info.dbFileName}`}
                                    download
                                    className="btn btn-info"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <i className="feather icon-download mr-2"></i>
                                    Download
                                  </a>
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => setDeleteUpload(info)}
                                    data-animation="slideInDown"
                                    data-toggle="modal"
                                    data-target="#deleteUploadModal"
                                  >
                                    <i className="feather icon-x mr-2"></i>
                                    Delete
                                  </button>
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {deleteUpload && (
                  <DeleteUploadModal
                    deleteUpload={deleteUpload}
                    loadContent={loadContent}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
